/**
 * @see {@link https://en.wikipedia.org/wiki/Combination}
 *
 * @param {array} arr
 * @param {number} x
 *
 * @returns {array}
 */
export function combinations(arr, x) {
  const res = [];

  const indexes = Array.from(new Array(x).keys());

  if (arr.length < x) {
    return res;
  }

  const maxIndexes = Array.from(new Array(arr.length).keys()).splice(-x);
  while (true) {
    res.push(indexes.map((i) => arr[i]));

    const idx = indexes.findLastIndex((i, idx) => i < maxIndexes[idx]);
    if (idx === -1) {
      return res;
    }

    indexes[idx]++;
    for (let i = idx + 1; i < indexes.length; i++) {
      indexes[i] = indexes[idx] - idx + i;
    }

    if (arr.length <= indexes[indexes.length - 1]) {
      return res;
    }
  }
}

function permutate(arr) {
  if (arr.length === 0) {
    return [];
  }

  if (arr.length === 1) {
    return [arr];
  }

  const res = [];
  for (let i = 0; i < arr.length; i++) {
    const curr = arr[i];
    const rem = arr.slice(0, i).concat(arr.slice(i + 1));
    const p = permutate(rem);
    for (let j = 0; j < rem.length; j++) {
      res.push([curr].concat(p[j]));
    }
  }

  return res;
}

/**
 * @see {@link https://en.wikipedia.org/wiki/Permutation}
 *
 * @param {array} arr
 * @param {number} x
 *
 * @returns {array}
 */
export function permutations(arr, x) {
  return combinations(arr, x).flatMap((c) => {
    return permutate(c);
  });
}

/**
 * @see {@link https://en.wikipedia.org/wiki/Permutation#Permutations_with_repetition}
 *
 * @param {array} arr
 * @param {number} x
 *
 * @returns {array}
 */
export function permutationsWithRepetition(arr, x) {
  const res = [];

  if (arr.length === 0) {
    return res;
  }

  const maxIndex = arr.length - 1;
  const indexes = new Array(x).fill(0);
  while (true) {
    res.push(indexes.map((i) => arr[i]));

    const idx = indexes.findLastIndex((i) => i < maxIndex);
    if (idx === -1) {
      return res;
    }

    indexes[idx]++;
    for (let i = idx + 1; i < indexes.length; i++) {
      indexes[i] = 0;
    }
  }
}

/**
 * @template T
 * @template K
 * @param {Array<T>} arr
 * @param {(val: T) => K} f
 *
 * @returns {Record<K, T[]>}
 */
export function groupBy(arr, f) {
  const res = {};

  if (arr == null) {
    return res;
  }

  for (const v of arr) {
    const k = f(v);
    if (!Object.prototype.hasOwnProperty.call(res, k)) {
      res[k] = [];
    }
    res[k].push(v);
  }

  return res;
}

/**
 * @template T
 * @template K
 * @param {Array<T>} arr
 * @param {(val: T) => K} f
 *
 * @returns {Record<K, T>}
 */
export function indexBy(arr, f) {
  const res = {};

  if (arr == null) {
    return res;
  }

  for (const v of arr) {
    const k = f(v);
    res[k] = v;
  }

  return res;
}

/**
 * @param  {Array<Function>} fs
 *
 * @returns {Function}
 */
export function compose(...fs) {
  const [f1, ...frest] = fs.reverse();
  return function (...args) {
    return frest.reduce((acc, fnext) => fnext(acc), f1(...args));
  };
}

/**
 * @param {object | null} m
 * @param {Array<String>} ks
 * @param {(oldVal) => any} f
 *
 * @returns {object}
 */
export function updateIn(m, ks, f) {
  const [k, ...ks2] = ks;
  if (m == null) {
    m = {};
  }

  if (ks2.length === 0) {
    const v = m[k];

    return { ...m, [k]: f(v) };
  }

  return { ...m, [k]: updateIn(m[k], ks2, f) };
}

/**
 * @param {object | null} m
 * @param {Array<String>} ks
 * @param {(oldVal) => any} f
 *
 * @returns {object}
 */
export function updateInIfExists(m, ks, f) {
  const [k, ...ks2] = ks;
  if (m == null) {
    return m;
  }

  if (ks2.length === 0) {
    const v = m[k];
    if (v == null) {
      return m;
    }

    return { ...m, [k]: f(v) };
  }

  return { ...m, [k]: updateInIfExists(m[k], ks2, f) };
}

/**
 * @param {object | null} m
 * @param {Array<string>} ks
 * @param {any} v
 *
 * @returns {object}
 */
export function assocInIfUnset(m, ks, v) {
  const [k, ...ks2] = ks;
  if (m == null) {
    m = {};
  }

  if (ks2.length === 0) {
    if (m[k] == null) {
      return { ...m, [k]: v };
    }

    return m;
  }

  return { ...m, [k]: assocInIfUnset(m[k], ks2, v) };
}

/**
 * @param {object | null} m
 * @param {Array<string>} ks
 * @param {(val: any) => boolean} f
 *
 * @returns {object | null}
 */
export function dissocInIf(m, ks, f) {
  const [k, ...ks2] = ks;
  if (m == null) {
    return m;
  }

  if (ks2.length === 0) {
    if (f(m[k])) {
      const { [k]: _, ...rest } = m;

      return rest;
    }

    return m;
  }

  return { ...m, [k]: dissocInIf(m[k], ks2, f) };
}

/**
 * @param {object | null} m
 * @param {Array<string>} ks
 *
 * @returns {object | null}
 */
export function getIn(m, ks) {
  const [k, ...ks2] = ks;
  if (m == null) {
    return;
  }

  if (ks2.length === 0) {
    return m[k];
  }

  return getIn(m[k], ks2);
}

/**
 * @template T
 * @param {T} f
 * @param {number} ms
 *
 * @returns {T}
 */
export function debounce(f, ms) {
  let timeoutId = null;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => f(...args), ms);
  };
}

/**
 * @template T
 * @param {T} f
 * @param {number} ms
 *
 * @returns {T}
 */
export function throttle(f, ms) {
  let lastRun = null;
  let timeoutId = null;
  return function (...args) {
    clearTimeout(timeoutId);

    const now = new Date();
    const nextRunIn = lastRun == null ? 0 : lastRun + ms - now;

    if (nextRunIn <= 0) {
      f(...args);
      lastRun = now.getTime();
      return;
    }

    timeoutId = setTimeout(() => {
      lastRun = new Date().getTime();
      f(...args);
    }, nextRunIn);
  };
}

/**
 * Takes async function `f` as argument and returns function with same signature as `f`.
 *
 * If resulting function is called, it calls `f` if it is not already running, or schedules
 * the run after current `f` finishes.
 *
 * It uses dropping buffer of size 1,
 * meaning that if more invokations are requested,
 * only last will be called.
 *
 * Returns promise, result of `f` is discarded.
 *
 * @template T
 * @param {T} f
 *
 * @returns {T}
 */
export function queued(f) {
  let nextF = null;
  let res = Promise.resolve();

  async function run() {
    if (nextF == null) {
      return;
    }

    const currentF = nextF;
    nextF = null;
    res = currentF[0](...currentF[1]);

    return res;
  }

  return async function (...args) {
    nextF = [f, args];
    try {
      await res;
    } finally {
      await run();
    }
  };
}

export function isAbortError(e) {
  return e?.name === "AbortError";
}

/**
 *
 * @param {Date} from1
 * @param {Date} to1
 * @param {Date} from2
 * @param {Date} to2
 *
 * @returns {Boolean}
 */
export function isOverlap(from1, to1, from2, to2) {
  return from1 < to2 && from2 < to1;
}

/**
 * @param {number} n Max jobs running at once
 */
export function createJobExecutor(n) {
  const jobs = new Set();

  return {
    /**
     * Run function `f` and return once `f` starts.
     *
     * @param {Function} f
     */
    execute: async function (f) {
      while (jobs.size >= n) {
        await Promise.any(jobs);
      }

      const p = f();
      jobs.add(p);
      p.then((_) => jobs.delete(p));
    },
    /**
     * Wait for all jobs to finish.
     */
    waitForAll: async function () {
      await Promise.all(jobs);
    },
  };
}

export function round(num, places) {
  const x = 10 * places;

  return Math.round(num * x) / x;
}
