import React, { useState } from "react";
import { Menu, MenuItem, Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import theme from "../theme.js";

const flags = {
  en: {
    code: "gb",
    countryName: "England",
    language: "English",
  },
  cs: {
    code: "cz",
    countryName: "Czech Republic",
    language: "Čeština",
  },
};

// Flag component to display the flag image dynamically
const Flag = ({ code }) => {
  const flag = flags[code] || flags.cs;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        filter: "grayscale(60%)",
        marginLeft: "8px",
      }}
    >
      <img
        src={`https://flagcdn.com/24x18/${flag.code}.png`}
        srcSet={`https://flagcdn.com/48x36/${flag.code}.png 2x,
        https://flagcdn.com/72x54/${flag.code}.png 3x`}
        width="24"
        height="18"
        alt={flag.countryName}
      />
    </Box>
  );
};

const TranslationSwitcher = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleMenuClose();
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Button
          id="language-switcher-menu-button"
          aria-controls="language-switcher-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleMenuClick}
          color="primary"
          size="xs"
          endIcon={
            <FontAwesomeIcon
              icon={faChevronDown}
              rotation={open ? 180 : 0}
              size="xs"
            />
          }
          sx={{
            color: "_components.heading.color",
            "& .MuiButton-endIcon": {
              color: theme.palette.grey[600],
            },
          }}
        >
          <Flag code={i18n.language} />
        </Button>
      </Box>
      <Menu
        id="language-switcher-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "language-switcher-menu-button",
        }}
      >
        <MenuItem onClick={() => changeLanguage("en")}>
          <Flag code="en" />
          <Typography sx={{ ml: 1 }}>English</Typography>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("cs")}>
          <Flag code="cs" />
          <Typography sx={{ ml: 1 }}>Čeština</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TranslationSwitcher;
