import React from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useAuthData } from "../context/AuthenticationTokenContext";
import * as auth from "../auth";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPowerOff } from "@fortawesome/pro-solid-svg-icons";

import { AppBarMenu, StyledMenuItem } from "./AppBarMenu.jsx";
import theme from "../theme.js";

export function UserMenu() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { first_name, last_name } = useAuthData();

  const fullName = `${first_name} ${last_name}`;
  const initials =
    `${first_name?.[0] || ""}${last_name?.[0] || ""}`.toUpperCase();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    handleClose();
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        id="user-menu-button"
        aria-controls={open ? "user-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleMenu}
        sx={{
          color: "_components.heading.color",
          "& .MuiButton-icon > *:first-of-type": {
            fontSize: 10,
          },
          "& .MuiButton-endIcon": {
            color: theme.palette.grey[600],
          },
        }}
        endIcon={
          <FontAwesomeIcon icon={faChevronDown} rotation={open ? 180 : 0} />
        }
      >
        <Avatar
          sx={{
            width: 35,
            height: 35,
            backgroundColor: "#A6D7FF",
            color: "#2196F3",
            fontSize: "1.6rem",
            fontWeight: 500,
            border: "2px solid #2196F3",
            mr: 1,
          }}
        >
          {initials}
        </Avatar>
        <Typography
          sx={{
            color: "_components.heading.color",
            fontWeight: 500,
          }}
        >
          {fullName}
        </Typography>
      </Button>
      <AppBarMenu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "user-menu-button",
        }}
      >
        <Divider />
        <StyledMenuItem onClick={handleLogout}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faPowerOff} />
          </ListItemIcon>
          <ListItemText>{t("userMenu.logout")}</ListItemText>
        </StyledMenuItem>
      </AppBarMenu>
    </Box>
  );
}
