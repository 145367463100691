import "@event-calendar/core/index.css";
import React, { useEffect, useState, memo } from "react";
import { useLoaderData, useRevalidator } from "react-router";
import { useTranslation } from "react-i18next";
import Link from "../components/Link";

import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { status } from "shared/src/appointment.mjs";
import * as d from "shared/src/date.mjs";
import * as api from "../services/api";
import * as f from "../utils/formatter";
import * as ws from "../utils/ws";
import { currentLocationFromRequest } from "../components/LocationSelector";
import { NewReservationSuggestionDialog } from "../components/NewReservationDialog.jsx";
import {
  faAngry,
  faCake,
  faCalendarDays,
  faCalendarPlus,
  faCheck,
  faCommentDots,
  faExclamationTriangle,
  faGlobe,
  faMagnifyingGlass,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAlarmClock,
  faChevronDoubleLeft,
  faHourglassClock,
  faSeatAirline,
} from "@fortawesome/pro-solid-svg-icons";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import ServiceTag from "../components/ServiceTag.jsx";
import Snackbar from "../components/Snackbar.jsx";

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  const now = new Date();
  const from = d.toString(d.startOfDay(now));
  const to = d.toString(d.endOfDay(now));

  return api.loadAppointments({
    filter: {
      start: { gte: from, lt: to },
      location_id: { eq: location_id },
    },
    order: {
      asc: "start",
    },
  });
}

export default function ReceptionDashboard() {
  const [reservationDialogOptions, setReservationDialogOptions] =
    useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [snackbarTitle, setSnackbarTitle] = useState();
  const loaderData = useLoaderData();
  const appointments = loaderData.data.appointments.data;
  const revalidator = useRevalidator();
  const { t } = useTranslation();

  const [renderedAppointments, setRenderedAppointments] =
    useState(appointments);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (!isHovered) {
      setRenderedAppointments(appointments);
    }
  }, [appointments, isHovered]);

  useEffect(() => {
    async function refresh(msg) {
      if (msg.type === "appointment_changed") {
        revalidator.revalidate();
      }
    }

    ws.subscribe(refresh);

    return () => {
      ws.unsubscribe(refresh);
    };
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h1">{t("reception.dashboard")}</Typography>
        <Button
          sx={{ padding: "0.7rem 2.4rem", gap: "0.6rem" }}
          variant="outlined"
          onClick={() =>
            setReservationDialogOptions({
              close: () => setReservationDialogOptions(null),
              showAdhocInput: true,
            })
          }
        >
          <FontAwesomeIcon icon={faCalendarDays} />
          {t("reception.reservation")}
        </Button>
      </Box>

      {reservationDialogOptions && (
        <NewReservationSuggestionDialog {...reservationDialogOptions} />
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 20%",
          gridTemplateRows: "2",
          gridTemplateAreas: `"booked treated" "checkedIn treated"`,
          width: "100%",
          gap: "36px",
        }}
      >
        <Box sx={{ gridArea: "booked" }}>
          <BookedClientList
            appointments={renderedAppointments}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarTitle={setSnackbarTitle}
            setSnackbarOpen={setSnackbarOpen}
            setIsHovered={setIsHovered}
          />
        </Box>
        <Box sx={{ gridArea: "checkedIn" }}>
          <CheckedInClientList
            appointments={renderedAppointments}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarTitle={setSnackbarTitle}
            setSnackbarOpen={setSnackbarOpen}
          />
        </Box>
        <Box sx={{ gridArea: "treated" }}>
          <TreatedClientList
            appointments={renderedAppointments}
            setReservationDialogOptions={setReservationDialogOptions}
          />
        </Box>
      </Box>

      <Snackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        title={snackbarTitle}
      >
        {snackbarMessage}
      </Snackbar>
    </>
  );
}

const ClientName = memo(({ appointment }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5em",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Link
          to={"/clients/" + appointment.client.id}
          style={{
            color: "inherit",
            textDecoration: "none",
            fontWeight: "bold",
            fontSize: "14px",
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
            minWidth: "0",
          }}
        >
          <div
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {f.fullName(appointment.client)}
          </div>
        </Link>
        {(appointment.status_id === status.CONFIRMED ||
          appointment.status_id === status.BOOKED) && (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            color={theme.palette.error.indicator}
          />
        )}
        <FontAwesomeIcon
          icon={faCake}
          color={theme.palette.warning.indicator}
        />
        <FontAwesomeIcon
          icon={faGlobe}
          color={theme.palette.success.indicator}
        />
        <FontAwesomeIcon icon={faAngry} color={theme.palette.error.indicator} />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          maxWidth: "100%",
        }}
      >
        <ServiceTag service={appointment.service} />
        <FontAwesomeIcon icon={faCommentDots} color="purple" />
      </div>
    </Box>
  );
});
ClientName.displayName = "ClientName";

const BookedClient = memo(
  ({ appointment, setSnackbarMessage, setSnackbarTitle, setSnackbarOpen }) => {
    const delayedBy = appointmentDelayedBy(appointment.start);
    const [openConfirmationModal, setOpenConfirmationModal] =
      React.useState(false);

    const handleOpenConfirmationModal = () => {
      setOpenConfirmationModal(true);
    };

    const handleCloseConfirmationModal = () => {
      setOpenConfirmationModal(false);
    };

    return (
      <>
        <Box
          sx={{
            borderBottomStyle: "solid",
            borderBottomColor: "divider",
            borderWidth: 1,
            padding: 1.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ flex: "0 0 70%", minWidth: "0", paddingRight: "10px" }}>
            <ClientName appointment={appointment} />
          </Box>
          <Box sx={{ flex: "0 0 20%", fontWeight: "bold", color: "grey" }}>
            {f.time24(appointment.start)}{" "}
            {!!delayedBy && (
              <Box
                component="span"
                sx={{ color: "_components.alert.error.color" }}
              >
                ({f.minutesToDuration(delayedBy)})
              </Box>
            )}
          </Box>
          <Box style={{ flex: "0 0 10%", textAlign: "right" }}>
            <IconButton
              sx={{
                borderRadius: 1,
                color: "white",
                backgroundColor: "secondary.main",
                width: "2.6rem",
                height: "2.6rem",
                fontSize: "1.8rem",
                "&:hover": {
                  color: "secondary.main",
                },
              }}
              onClick={handleOpenConfirmationModal}
            >
              <FontAwesomeIcon icon={faPlus} />
            </IconButton>
          </Box>
        </Box>
        <ConfirmationModal
          appointment={appointment}
          handleCloseConfirmationModal={handleCloseConfirmationModal}
          openConfirmationModal={openConfirmationModal}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarTitle={setSnackbarTitle}
          setSnackbarOpen={setSnackbarOpen}
          newStatus={status.CONFIRMED}
        />
      </>
    );
  },
);
BookedClient.displayName = "BookedClient";

function CheckedInClient({
  appointment,
  setSnackbarMessage,
  setSnackbarTitle,
  setSnackbarOpen,
}) {
  const [openAppointmentDetailModal, setOpenAppointmentDetailModal] =
    React.useState(false);
  const handleOpenAppointmentDetailModal = () =>
    setOpenAppointmentDetailModal(true);
  const handleCloseAppointmentDetailModal = () =>
    setOpenAppointmentDetailModal(false);

  const [openConfirmationModal, setOpenConfirmationModal] =
    React.useState(false);
  const handleOpenConfirmationModal = () => setOpenConfirmationModal(true);
  const handleCloseConfirmationModal = () => setOpenConfirmationModal(false);

  let checkedInList = appointment.audit.data.filter(
    (appointment) =>
      appointment.changed_fields?.status_id === String(status.CONFIRMED),
  );

  if (checkedInList.length === 0) {
    checkedInList = appointment.audit.data.filter(
      (appointment) =>
        appointment.changed_fields?.status_id === String(status.WAITING),
    );
  }

  const checkedInMinutes = getCheckedInMinutes(
    checkedInList[checkedInList.length - 1],
    appointment,
  );

  const waitingMinutes = getWaitingTime(
    checkedInList[checkedInList.length - 1],
    appointment,
  );

  return (
    <Box
      sx={{
        display: "flex",
        borderBottomStyle: "solid",
        borderBottomColor: "divider",
        borderWidth: 1,
        alignItems: "center",
        padding: "12px",
      }}
    >
      <Box sx={{ flex: "0 0 60%" }}>
        <ClientName appointment={appointment} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "0 0 40%",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={handleOpenAppointmentDetailModal}
      >
        <Box sx={{ flex: "0 0 30%", color: "grey" }}>
          {f.time24(appointment.start)}
        </Box>
        <Box sx={{ flex: "0 0 fit-content", textAlign: "left" }}>
          <Box
            sx={{
              color:
                checkedInMinutes > 0 ? "_components.alert.error.color" : "blue",
            }}
          >
            <FontAwesomeIcon
              icon={faHourglassClock}
              style={{ marginRight: "5px" }}
            />
            {f.minutesToDuration(checkedInMinutes)}
          </Box>
          <Box
            sx={{
              color:
                waitingMinutes > 0 ? "_components.alert.error.color" : "blue",
            }}
          >
            <FontAwesomeIcon
              icon={faAlarmClock}
              style={{ marginRight: "5px" }}
            />
            {f.minutesToDuration(waitingMinutes)}
          </Box>
        </Box>
      </Box>
      <AppointmentDetailModal
        appointment={appointment}
        handleCloseAppointmentDetailModal={handleCloseAppointmentDetailModal}
        openAppointmentDetailModal={openAppointmentDetailModal}
        checkedInMinutes={checkedInMinutes}
        waitingMinutes={waitingMinutes}
        handleOpenConfirmationModal={handleOpenConfirmationModal}
      />
      <ConfirmationModal
        appointment={appointment}
        handleCloseConfirmationModal={handleCloseConfirmationModal}
        openConfirmationModal={openConfirmationModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarTitle={setSnackbarTitle}
        setSnackbarOpen={setSnackbarOpen}
        newStatus={status.BOOKED}
      />
    </Box>
  );
}

function getCheckedInMinutes(checkedIn, appointment) {
  if (checkedIn) {
    const checkedInTime = d.parseUtcDateTime(checkedIn.created_at);
    const appointmentTime = d.parseDateTime(appointment.start);
    const diff = checkedInTime - appointmentTime;

    return Math.floor(diff / 1000 / 60);
  }
}

function getWaitingTime(checkedIn, appointment) {
  if (checkedIn) {
    const checkedInTime = d.parseUtcDateTime(checkedIn.created_at);
    const appointmentTime = d.parseDateTime(appointment.start);
    const diff = checkedInTime - appointmentTime;

    const now = new Date();
    let time;
    if (diff < 0) {
      time = now - appointmentTime;
    } else {
      time = now - checkedInTime;
    }
    return Math.floor(time / 1000 / 60);
  }
}

function TreatedClient({ appointment, setReservationDialogOptions }) {
  return (
    <div
      style={{
        borderBottom: "1px solid lightblue",
        padding: "12px",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flex: "0 0 60%",
        }}
      >
        <Link
          to={"/clients/" + appointment.client.id}
          style={{
            color: "inherit",
            textDecoration: "none",
            fontWeight: "bold",
            fontSize: "14px",
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {f.fullName(appointment.client)}
          </span>
        </Link>
        <FontAwesomeIcon icon={faGlobe} color="green" />
        <FontAwesomeIcon icon={faAngry} color="red" />
      </div>

      <IconButton
        onClick={() =>
          setReservationDialogOptions({
            close: () => setReservationDialogOptions(null),
            showAdhocInput: false,
          })
        }
        sx={{
          borderRadius: 1,
          color: "white",
          backgroundColor: "primary.main",
          width: "2.6rem",
          height: "2.6rem",
          fontSize: "1.6rem",
          "&:hover": {
            color: "primary.main",
          },
        }}
      >
        <FontAwesomeIcon icon={faCalendarPlus} />{" "}
      </IconButton>
    </div>
  );
}

function BookedClientHeader() {
  const { t } = useTranslation();

  return (
    <ColumnHeader>
      <span style={{ flex: "0 0 70%" }}>{t("reception.client_name")}</span>
      <span style={{ flex: "0 0 20%" }}>{t("reception.start")}</span>
      <span style={{ flex: "0 0 10%" }}></span>
    </ColumnHeader>
  );
}

function BookedClientList({
  appointments,
  setSnackbarMessage,
  setSnackbarTitle,
  setSnackbarOpen,
  setIsHovered,
}) {
  const [bookedClients, setBookedClients] = useState(appointments);
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (searchTerm) {
      setBookedClients(
        searchAppointments(appointments, searchTerm, [
          status.BOOKED,
          status.NOSHOW,
        ]),
      );
    } else {
      setBookedClients(
        appointments.filter(
          (appointment) => appointment.status_id === status.BOOKED,
        ),
      );
    }
  }, [appointments, searchTerm]);

  return (
    <Card onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h2">{t("reception.booked_clients")}</Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            placeholder={t("reception.search")}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "divider",
                },
                "& input::placeholder": {
                  opacity: 0.9,
                  color: "var(--mui-palette-_components-heading-color)",
                },
              },
            }}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "grid",
            gap: "0 1rem",
            gridAutoFlow: "row",
            gridTemplateColumns: "repeat(3, 1fr)",
          }}
        >
          <BookedClientHeader />
          <BookedClientHeader />
          <BookedClientHeader />
          {bookedClients
            .filter(
              (appointment) => appointmentDelayedBy(appointment.start) < 20,
            )
            .slice(0, 15)
            .map((appointment) => (
              <BookedClient
                appointment={appointment}
                key={appointment.id}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarTitle={setSnackbarTitle}
                setSnackbarOpen={setSnackbarOpen}
                setIsHovered={setIsHovered}
              />
            ))}
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            marginTop: "2rem",
            alignItems: "center",
            gap: "1rem",
          }}
        ></div>
      </CardContent>
    </Card>
  );
}

function ColumnHeader({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 1.5,
        py: 1,
        textAlign: "left",
        backgroundColor: "background.default",
        fontSize: "1rem",
      }}
    >
      {children}
    </Box>
  );
}

function CheckedInClientHeader() {
  const { t } = useTranslation();
  return (
    <ColumnHeader>
      <span style={{ flex: "0 0 60%" }}>{t("reception.client_name")}</span>
      <span
        style={{
          display: "flex",
          flex: "0 0 40%",
          justifyContent: "space-between",
        }}
      >
        <span style={{ flex: "0 0 30%" }}>{t("reception.start")}</span>
        <span
          style={{ flex: "0 0 70%", whiteSpace: "nowrap", textAlign: "right" }}
        >
          {t("reception.wait_time")}
        </span>
      </span>
    </ColumnHeader>
  );
}

function CheckedInClientList({
  appointments,
  setSnackbarMessage,
  setSnackbarTitle,
  setSnackbarOpen,
}) {
  const [checkedInClients, setCheckedInClients] = useState(appointments);
  const { t } = useTranslation();

  useEffect(() => {
    setCheckedInClients(
      appointments
        .filter(
          (appointment) =>
            appointment.status_id === status.CONFIRMED ||
            appointment.status_id === status.WAITING,
        )
        .sort((a, b) => a.status_id - b.status_id),
    );
  }, [appointments]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" gutterBottom>
            {t("reception.checked_in_clients")}
          </Typography>
        </Box>

        <div>
          <div
            style={{
              display: "grid",
              gap: "0 1rem",
              gridAutoFlow: "row",
              gridTemplateColumns: "1fr 1fr 1fr",
            }}
          >
            <CheckedInClientHeader />
            <CheckedInClientHeader />
            <CheckedInClientHeader />
          </div>
        </div>

        <div>
          <div
            style={{
              display: "grid",
              gap: "0 1rem",
              gridAutoFlow: "row",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridAutoRows: "1fr",
            }}
          >
            {checkedInClients.map((appointment) => (
              <CheckedInClient
                key={appointment.id}
                appointment={appointment}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarTitle={setSnackbarTitle}
                setSnackbarOpen={setSnackbarOpen}
              />
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function TreatedClientHeader() {
  const { t } = useTranslation();

  return (
    <ColumnHeader>
      <span>{t("reception.client_name")}</span>
      <span>{t("reception.book")}</span>
    </ColumnHeader>
  );
}

function TreatedClientList({ appointments, setReservationDialogOptions }) {
  const [treatedClients, setTreatedClients] = useState(appointments);
  const { t } = useTranslation();

  useEffect(() => {
    setTreatedClients(
      appointments
        .filter((appointment) => appointment.status_id === status.FINISHED)
        .sort(
          (a, b) =>
            new Date(
              a.audit.data.findLast(
                (change) =>
                  change?.changed_fields?.status_id === String(status.FINISHED),
              )?.created_at,
            ).getTime() -
            new Date(
              b.audit.data.findLast(
                (change) =>
                  change?.changed_fields?.status_id === String(status.FINISHED),
              )?.created_at,
            ).getTime(),
        ),
    );
  }, [appointments]);

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h2" gutterBottom>
            {t("reception.treated_clients")}
          </Typography>
        </Box>

        <div
          style={{
            display: "grid",
            gridAutoFlow: "row",
            gridTemplateColumns: "1fr",
          }}
        >
          <TreatedClientHeader />

          {treatedClients.map((appointment) => (
            <TreatedClient
              key={appointment.id}
              appointment={appointment}
              setReservationDialogOptions={setReservationDialogOptions}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

function searchAppointments(appointments, searchTerm, statuses = []) {
  const searchTerms = searchTerm.split(" ").map((term) =>
    term
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, ""),
  );

  let filteredAppointments = appointments;
  if (statuses.length) {
    filteredAppointments = filteredAppointments.filter((appointment) =>
      statuses.includes(appointment.status_id),
    );
  }
  searchTerms.forEach((term) => {
    filteredAppointments = filteredAppointments.filter(
      (appointment) =>
        appointment.client.first_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(term) ||
        appointment.client.last_name
          .toLowerCase()
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .includes(term) ||
        appointment.client.birth_number?.includes(term) ||
        appointment.client.phone?.includes(term) ||
        appointment.start.split(" ")[1].includes(term) ||
        appointment.start.split(" ")[1].replace(":", "").includes(term),
    );
  });
  return filteredAppointments;
}

function appointmentDelayedBy(appointmentTime) {
  const now = new Date();
  const appointment = d.parseDateTime(appointmentTime);
  const diff = now - appointment;
  if (diff < 0) {
    return 0;
  }
  const totalMinutes = Math.floor(diff / 1000 / 60);

  return totalMinutes;
}

function ConfirmationModal({
  appointment,
  handleCloseConfirmationModal,
  openConfirmationModal,
  setSnackbarMessage,
  setSnackbarTitle,
  setSnackbarOpen,
  newStatus,
}) {
  const { t } = useTranslation();
  const handleMoveAppointment = () => {
    handleCloseConfirmationModal();
    api
      .patchAppointment({
        id: appointment.id,
        status_id: newStatus,
      })
      .then(() => {
        setSnackbarMessage(
          <span>
            {t("reception.client_moved_checked_in", {
              name: f.fullName(appointment.client),
            })}
          </span>,
        );
        setSnackbarTitle(t("reception.info"));
        setSnackbarOpen(true);
      });
  };

  return (
    <Dialog
      open={openConfirmationModal}
      onClose={handleCloseConfirmationModal}
      maxWidth="xs"
    >
      <DialogTitle
        sx={{
          padding: "2.4rem 2rem",
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {newStatus === status.CONFIRMED && t("reception.move_to_checked_in")}
        {newStatus === status.BOOKED && t("reception.move_to_booked")}
        <IconButton aria-label="close" onClick={handleCloseConfirmationModal}>
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ padding: "0 4.4rem" }}>
        <strong>{f.fullName(appointment.client)}</strong>{" "}
        {newStatus === status.CONFIRMED && t("reception.will_be_checked_in")}
        {newStatus === status.BOOKED && t("reception.will_be_booked")}
      </DialogContent>
      <DialogActions sx={{ padding: "3.5rem 2rem 3rem 2rem" }}>
        <Button variant="outlined" onClick={handleCloseConfirmationModal}>
          {t("reception.cancel")}
        </Button>
        <Button variant="contained" onClick={handleMoveAppointment}>
          {t("reception.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function AppointmentDetailModal({
  appointment,
  handleCloseAppointmentDetailModal,
  openAppointmentDetailModal,
  checkedInMinutes,
  waitingMinutes,
  handleOpenConfirmationModal,
}) {
  const { t } = useTranslation();

  const handleMoveAppointment = (status) => {
    handleCloseAppointmentDetailModal();
    api.patchAppointment({
      id: appointment.id,
      status_id: status,
    });
  };

  return (
    <Dialog
      open={openAppointmentDetailModal}
      onClose={handleCloseAppointmentDetailModal}
    >
      <DialogTitle
        sx={{
          padding: "2.4rem 2rem",
          margin: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {f.fullName(appointment.client)}
        <IconButton
          aria-label="close"
          onClick={handleCloseAppointmentDetailModal}
        >
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          padding: "0 3.8rem",
        }}
      >
        <ServiceTag service={appointment.service} />

        <div>
          <strong style={{ fontSize: "1.3rem" }}>
            {t("reception.appointment_start")}:
          </strong>{" "}
          {f.time24(appointment.start)}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "min-content 1fr",
            gap: "10px",
          }}
        >
          {appointment.status_id === status.CONFIRMED && (
            <>
              <FontAwesomeIcon icon={faExclamationTriangle} color="red" />
              <div>
                <div style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                  {t("reception.to_finalize")}
                </div>
                <span>Lorem ipsum dolor xxx</span>{" "}
                <Link>{t("reception.resolve")}</Link>
              </div>
            </>
          )}

          <FontAwesomeIcon icon={faGlobe} color="green" />
          <div>
            <div style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
              {t("reception.clients_language")}
            </div>
            <span>xxx</span>
          </div>
          <FontAwesomeIcon icon={faAngry} color="red" />
          <div>
            <div style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
              {t("reception.problematic_client")}
            </div>
            <span>xxx</span>
          </div>
          <FontAwesomeIcon icon={faCommentDots} color="purple" />
          <div>
            <div style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
              {t("reception.important_note")}
            </div>
            <span>xxx</span>
          </div>
        </div>

        <div style={{ color: "blue", fontWeight: "bold", fontSize: "1.3rem" }}>
          <FontAwesomeIcon icon={faSeatAirline} />{" "}
          {t("reception.seat_preparing")}
        </div>

        <div style={{ display: "flex", gap: "2rem" }}>
          <div>
            <FontAwesomeIcon
              icon={faAlarmClock}
              style={{ marginRight: "5px" }}
            />
            <strong style={{ fontSize: "1.3rem" }}>
              {t("reception.waiting")}
            </strong>
            :{" "}
            <span style={{ color: waitingMinutes > 0 ? "red" : "blue" }}>
              {f.minutesToDuration(waitingMinutes)}
            </span>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faHourglassClock}
              style={{ marginRight: "5px" }}
            />
            <strong style={{ fontSize: "1.3rem" }}>
              {t("reception.arrival")}
            </strong>
            :{" "}
            <span style={{ color: checkedInMinutes > 0 ? "red" : "blue" }}>
              {f.minutesToDuration(checkedInMinutes)}
            </span>
          </div>
        </div>
      </DialogContent>

      <DialogActions sx={{ padding: "3.5rem 2rem 3rem 2rem" }}>
        {appointment.status_id === status.CONFIRMED && (
          <Button
            variant="contained"
            onClick={() => handleMoveAppointment(status.WAITING)}
            style={{ fontSize: "1.4rem" }}
          >
            <FontAwesomeIcon icon={faCheck} style={{ marginRight: "8px" }} />
            {t("reception.resolved")}
          </Button>
        )}
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            handleCloseAppointmentDetailModal();
            handleOpenConfirmationModal();
          }}
          style={{ fontSize: "1.4rem" }}
        >
          <FontAwesomeIcon
            icon={faChevronDoubleLeft}
            style={{ marginRight: "8px" }}
          />
          {t("reception.return_to_booked")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
