import { styled } from "@mui/material/styles";
import { Menu, MenuItem } from "@mui/material";
import theme from "../theme.js";

export const AppBarMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.default,
  },
}));

export const StyledMenuItem = styled(MenuItem)({
  padding: "12px 16px",
  justifyContent: "center",
  "&.MuiMenuItem-root": {
    color: theme.palette._components.heading.color,
    "&.Mui-focusVisible, &:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
    },
    "&:hover .MuiListItemIcon-root": {
      color: "white",
    },
    ".MuiListItemIcon-root": {
      color: theme.palette._components.heading.color,
      minWidth: 24,
    },
  },
});
