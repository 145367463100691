import React from "react";
import * as api from "../services/api";
import { useLatest } from "../hooks";
import { useSearchParams } from "react-router";
import { Box, Button, ListItemText, Typography } from "@mui/material";
import { AppBarMenu, StyledMenuItem } from "./AppBarMenu.jsx";
import {
  faHouseChimneyMedical,
  faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../theme.js";

async function fetchLocationOptions(signal) {
  const res = await api.locationOptions({ signal });

  return res.data.locations.data;
}

function useLocationOptions() {
  const [options, setOptions] = React.useState();
  useLatest(async (signal) => {
    setOptions(null);
    setOptions(await fetchLocationOptions(signal));
  }, []);

  return React.useMemo(
    () => ({ options: options ?? [], loading: options == null }),
    [options],
  );
}

function useChangeQuery() {
  const [_, setSearchParams] = useSearchParams();
  return React.useCallback(
    (newValues) => {
      setSearchParams((prev) => ({
        ...Object.fromEntries(prev),
        ...Object.fromEntries(
          Object.entries(newValues).filter(([_, v]) => v != null),
        ),
      }));
    },
    [setSearchParams],
  );
}

export function currentLocationFromRequest(request) {
  const url = new URL(request.url);

  return url.searchParams.get("g_location");
}

export function useCurrentLocation() {
  const [searchParams] = useSearchParams();

  return searchParams.get("g_location");
}

export function LocationSelector() {
  const locationOptions = useLocationOptions();
  const changeQuery = useChangeQuery();
  const locationId = useCurrentLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (e, locationId) => {
    changeQuery({ g_location: locationId });
    setAnchorEl(null);
  };

  React.useEffect(() => {
    const locationIds = locationOptions.options.map((o) => o.id);
    if (locationIds.length === 0) {
      return;
    }

    if (locationIds.includes(locationId)) {
      return;
    }

    const firstLocationId = locationIds[0];
    if (firstLocationId == null) {
      return;
    }

    changeQuery({ g_location: firstLocationId });
  }, [locationId, locationOptions]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        id="location-select-menu-button"
        aria-controls={open ? "location-select-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleMenu}
        sx={{
          color: "_components.heading.color",
          "& .MuiButton-icon > *:first-of-type": {
            fontSize: 10,
          },
          "& .MuiButton-endIcon": {
            color: theme.palette.grey[600],
          },
        }}
        startIcon={<FontAwesomeIcon icon={faHouseChimneyMedical} />}
        endIcon={
          <FontAwesomeIcon icon={faChevronDown} rotation={open ? 180 : 0} />
        }
      >
        <Typography>
          {locationOptions.options.find((o) => o.id === locationId)?.name ??
            null}
        </Typography>
      </Button>

      <AppBarMenu
        id="location-select-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "location-select-menu-button",
        }}
      >
        {locationOptions.options?.map((o) => (
          <StyledMenuItem
            key={o.id}
            onClick={(event) => handleMenuItemClick(event, o.id)}
          >
            <ListItemText>{o.name}</ListItemText>
          </StyledMenuItem>
        ))}
      </AppBarMenu>
    </Box>
  );
}
