import React from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "shared/src/util.mjs";
import { DEBOUNCE_INPUT } from "../constants";
import { createClientFilter } from "../client";
import * as api from "../services/api";
import { FormErrorList, useFormAction } from "../form";
import * as d from "shared/src/date.mjs";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import * as f from "../utils/formatter";
import AutocompleteSelect from "./AutocompleteSelect";
import DateRangeSelector from "./DateRangeSelector";
import ButtonLoader from "./ButtonLoader";
import { useLatest } from "../hooks";
import { status } from "shared/src/appointment.mjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";

/**
 * @param {AbortSignal} signal
 */
async function fetchServiceOptions(signal, filter) {
  if (filter == null) {
    return [];
  }

  const res = await api.serviceOptions(
    {
      filter: filter,
    },
    { signal },
  );

  return res.data.services.data.map((s) => ({
    ...s,
    name: `${s.name} (${f.minutesToDuration(s.duration)})`,
  }));
}

function useServiceOptions(filter) {
  const [options, setOptions] = React.useState();
  useLatest(
    async (signal) => {
      setOptions(null);
      setOptions(await fetchServiceOptions(signal, filter));
    },
    [filter],
  );

  return { options: options ?? [], loading: options == null };
}

async function doClientSearch(signal, term, location_id) {
  let filter = createClientFilter(term);
  if (filter == null) {
    return [];
  }

  if (location_id != null) {
    filter = { and: [filter, { location_id: { eq: location_id } }] };
  }

  const res = await api.clientSearch(
    {
      filter: filter,
      page: { limit: 10, offset: 0 },
    },
    { signal },
  );

  return res.data.clients.data;
}

function useClients(term, location_id) {
  const [state, setState] = React.useState({ result: [], loading: false });
  useLatest(
    async (signal) => {
      setState((state) => ({ ...state, loading: true }));
      try {
        const result = await doClientSearch(signal, term, location_id);
        setState((state) => ({ ...state, result }));
      } finally {
        if (!signal.aborted) {
          setState((state) => ({ ...state, loading: false }));
        }
      }
    },
    [term, location_id],
  );

  return state;
}

function useClientOptions(location_id = null, option = null) {
  const [term, setTerm] = React.useState("");
  const { result, loading } = useClients(term, location_id);
  const options = React.useMemo(() => {
    if (option == null) {
      return result;
    }

    if (result.find((opt) => opt.id === option.id)) {
      return result;
    }

    return [option, ...result];
  }, [option, result]);

  return [
    options,
    React.useCallback(debounce(setTerm, DEBOUNCE_INPUT), []),
    loading,
  ];
}

/**
 * @param {AbortSignal} signal
 */
async function fetchLocationOptions(signal) {
  const res = await api.locationOptions({ signal });

  return res.data.locations.data;
}

function useLocationOptions() {
  const [options, setOptions] = React.useState();
  useLatest(async (signal) => {
    setOptions(await fetchLocationOptions(signal));
  }, []);

  return { options: options ?? [], loading: options == null };
}

/**
 * @typedef NewReservationDialogDefaults
 * @property {boolean} [adhoc]
 *
 * @typedef NewReservationSuggestionDialogState
 * @property {object | null} option
 * @property {object | null} serviceOption
 * @property {object | null} locationOption
 * @property {{from: string, to: string}[]} days
 * @property {object[]} suggestions
 * @property {boolean} suggestionsLoading
 * @property {object | null} suggeestion
 *
 * @param {NewReservationDialogDefaults} defaults
 *
 * @returns {NewReservationSuggestionDialogState}
 */
function initialNewReservationSuggestionDialogState(defaults) {
  return {
    adhoc: defaults.adhoc ?? false,
    option: null,
    serviceOption: null,
    locationOption: null,
    days: [],
    suggestions: [],
    suggestionsLoading: false,
    suggestion: null,
  };
}

async function fetchSuggestions(signal, filter) {
  const suggestions = await api.appointmentSuggestions(
    {
      filter: filter,
    },
    { signal: signal },
  );

  return suggestions.data.appointmentSuggestions.data.map((r) => {
    return {
      id: `${r.start}.${r.end}.${r.calendar_id}`,
      ...r,
    };
  });
}

/**
 * @param {NewReservationSuggestionDialogState} state
 */
function useSuggestions(setState, state) {
  const { option, serviceOption, locationOption, days } = state;

  useLatest(
    async (signal) => {
      if (
        option == null ||
        serviceOption == null ||
        locationOption == null ||
        days.length === 0
      ) {
        setState((state) => ({
          ...state,
          suggestions: [],
          suggestion: null,
          suggestionsLoading: false,
        }));
        return;
      }

      setState((state) => ({
        ...state,
        suggestion: null,
        suggestionsLoading: true,
      }));

      const filter = {
        location_id: locationOption.id,
        service_id: serviceOption.id,
        duration: serviceOption.duration,
        days,
      };
      const suggestions = await fetchSuggestions(signal, filter);

      setState((state) => ({
        ...state,
        suggestion: null,
        suggestionsLoading: false,
        suggestions: suggestions,
      }));
    },
    [option, serviceOption, locationOption, days],
  );
}

function ClientAutocomplete({ search, options, option, onChange, loading }) {
  const { t } = useTranslation();

  return (
    <>
      <input type="hidden" name="client_id" value={option?.id ?? ""} />
      <Autocomplete
        sx={{ minWidth: "400px" }}
        filterOptions={(x) => x}
        autoComplete
        loading={loading}
        options={options}
        getOptionLabel={(opt) => f.fullName(opt)}
        getOptionKey={(opt) => opt.id}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        renderInput={(params) => (
          <TextField label={t("appointment.client")} required {...params} />
        )}
        renderOption={(props, option) => {
          const { key, ...otherProps } = props;

          return (
            <li key={key} {...otherProps}>
              <Box>
                <strong>{f.fullName(option)}</strong>
                <br />
                {t("client.birth_number")}: {option.birth_number}{" "}
                {t("client.phone")}: {option.phone}
                <br />
                {option.street} {option.city}
                <br />
                {option.post_code}
              </Box>
            </li>
          );
        }}
        onInputChange={(_, term) => {
          search(term);
        }}
        onChange={(_, value) => {
          onChange(value == null ? null : value);
        }}
      />
    </>
  );
}

/**
 * @param {AbortSignal} signal
 * @param {string | null} client_id
 */
async function fetchFutureAppointments(signal, client_id) {
  if (client_id == null) {
    return [];
  }

  const res = await api.futureAppointments(
    {
      filter: {
        start: { gte: d.toString(new Date()) },
        client_id: { eq: client_id },
      },
    },
    { signal: signal },
  );

  return res.data.appointments.data;
}

function useFutureAppointments(client_id) {
  const [data, setData] = React.useState();

  useLatest(
    async (signal) => {
      setData(null);
      setData(await fetchFutureAppointments(signal, client_id));
    },
    [client_id],
  );

  return { data: data ?? [], loading: data == null };
}

function FutureAppointment({ appointment }) {
  return (
    <Box>
      {f.date(appointment.start)} - {appointment.service?.name}
    </Box>
  );
}

function FutureAppointments({ client_id }) {
  const futureAppointments = useFutureAppointments(client_id);

  return (
    <Box>
      <FormLabel>Naplánované služby</FormLabel>
      <br />
      {futureAppointments.loading ? (
        <CircularProgress size={24} sx={{ position: "absolute" }} />
      ) : (
        futureAppointments.data.map((a) => (
          <FutureAppointment key={a.id} appointment={a} />
        ))
      )}
    </Box>
  );
}

/**
 * @param {object} props
 * @param {() => void} props.close
 * @param {boolean} [props.showAdhocInput]
 * @param {NewReservationDialogDefaults} [props.defaults]
 */
export function NewReservationSuggestionDialog({
  close,
  showAdhocInput,
  defaults = {},
}) {
  const { t } = useTranslation();
  const [state, setState] = React.useState(() =>
    initialNewReservationSuggestionDialogState(defaults),
  );
  const {
    option,
    serviceOption,
    locationOption,
    suggestions,
    suggestion,
    adhoc,
  } = state;
  const [options, clientSearch, clientOptionsLoading] = useClientOptions(
    null,
    option,
  );
  const locationOptions = useLocationOptions();
  const serviceFilter = React.useMemo(() => {
    return locationOption == null
      ? null
      : { location_id: { eq: locationOption.id } };
  }, [locationOption]);
  const so = useServiceOptions(serviceFilter);

  const { errors, loading, onSubmit } = useFormAction(
    () => {
      if (adhoc) {
        const start = new Date();
        const end = new Date(start.getTime());
        end.setMinutes(end.getMinutes() + serviceOption.duration);

        return api.createAppointment({
          location_id: locationOption.id,
          client_id: option.id,
          service_id: serviceOption.id,
          start: d.toString(start),
          end: d.toString(end),
          status_id: status.WAITING,
        });
      }

      return api.createAppointment({
        calendar_id: suggestion.calendar_id,
        client_id: option.id,
        service_id: serviceOption.id,
        start: d.toString(new Date(suggestion.start)),
        end: d.toString(new Date(suggestion.end)),
      });
    },
    () => close(),
  );

  useSuggestions(setState, state);
  const submitDisabled =
    loading ||
    (adhoc
      ? option == null || serviceOption == null || locationOption == null
      : suggestion == null);

  return (
    <Dialog open={true} onClose={() => close()}>
      <DialogTitle>
        {t("calendar.newReservation")}
        <IconButton aria-label="close" onClick={() => close()}>
          <FontAwesomeIcon icon={faXmark} fixedWidth />
        </IconButton>
      </DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="1rem">
            {showAdhocInput && (
              <FormControlLabel
                control={<Checkbox checked={adhoc} />}
                label="Je adhoc"
                onChange={(_, checked) => {
                  setState((state) => ({ ...state, adhoc: checked }));
                }}
              />
            )}
            <ClientAutocomplete
              search={clientSearch}
              loading={clientOptionsLoading || locationOptions.loading}
              options={options}
              option={option}
              onChange={(option) =>
                setState((state) => ({
                  ...state,
                  option: option,
                  locationOption:
                    option == null
                      ? null
                      : (locationOptions.options.find(
                          (o) => o.id === option.location_id,
                        ) ?? null),
                }))
              }
            />
            <AutocompleteSelect
              required
              loading={locationOptions.loading}
              options={locationOptions.options}
              label={t("calendars.location")}
              value={locationOption}
              readOnly={true}
            />
            <AutocompleteSelect
              required
              name="service_id"
              loading={so.loading}
              options={so.options}
              label={t("appointment.service")}
              onChange={(value) => {
                setState((state) => ({
                  ...state,
                  serviceOption: value == null ? null : value,
                }));
              }}
            />
            {!adhoc && (
              <>
                <DateRangeSelector
                  onChange={(days) => {
                    setState((state) => ({
                      ...state,
                      days,
                    }));
                  }}
                />
                <FormControl required disabled={state.suggestionsLoading}>
                  <FormLabel>{t("appointment.suggestedTimes")}</FormLabel>
                  <RadioGroup
                    onChange={(_, value) => {
                      setState((state) => ({
                        ...state,
                        suggestion: suggestions.find((s) => s.id === value),
                      }));
                    }}
                  >
                    {suggestions.map((s) => {
                      const label = (
                        <>
                          {f.longDateTime(s.start)} - {f.time(s.end)}
                          <br />({s.calendar.name})
                        </>
                      );

                      return (
                        <FormControlLabel
                          key={s.id}
                          value={s.id}
                          control={<Radio />}
                          label={label}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                {!state.suggestionsLoading && suggestions.length === 0 && (
                  <Typography>{t("fcalendar.suggestionsEmpty")}</Typography>
                )}
                <FutureAppointments client_id={option?.id} />
              </>
            )}
            <FormErrorList formErrors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()} variant="outlined">
            {t("form.cancel")}
          </Button>
          <Button type="submit" disabled={submitDisabled} variant="contained">
            {loading && <ButtonLoader />}
            {t("form.submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function initialNewReservationDialogState(defaults) {
  return {
    option: null,
    serviceOption: null,
    from: null,
    ...defaults,
  };
}

export function NewReservationDialog({ defaults, close, location_id }) {
  const { calendar_id, from: fromDefault } = defaults;
  const { t } = useTranslation();
  const [state, setState] = React.useState(() =>
    initialNewReservationDialogState({ from: fromDefault }),
  );
  const { option, serviceOption, from } = state;
  const [options, clientSearch, clientOptionsLoading] = useClientOptions(
    location_id,
    option,
  );
  const serviceFilter = React.useMemo(() => {
    return { calendar_id: { eq: calendar_id } };
  }, [calendar_id]);
  const so = useServiceOptions(serviceFilter);

  const { errors, loading, onSubmit } = useFormAction(
    () => {
      const fromDate = new Date(from);
      const toDate = new Date(fromDate.getTime());
      toDate.setMinutes(toDate.getMinutes() + serviceOption.duration);

      return api.createAppointment({
        calendar_id: calendar_id,
        client_id: option.id,
        service_id: serviceOption.id,
        start: d.toString(fromDate),
        end: d.toString(toDate),
      });
    },
    () => close(),
  );

  return (
    <Dialog open={true} onClose={() => close()}>
      <DialogTitle>{t("calendar.newReservation")}</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap="1rem">
            <ClientAutocomplete
              search={clientSearch}
              loading={clientOptionsLoading}
              options={options}
              option={option}
              onChange={(option) =>
                setState((state) => ({
                  ...state,
                  option: option,
                }))
              }
            />
            <AutocompleteSelect
              required
              name="service_id"
              loading={so.loading}
              options={so.options}
              label={t("appointment.service")}
              onChange={(value) => {
                setState((state) => ({
                  ...state,
                  serviceOption: value == null ? null : value,
                }));
              }}
            />
            <TextField
              name="from"
              label={t("appointment.from")}
              type="datetime-local"
              required
              defaultValue={fromDefault}
              onChange={(e) => {
                const value =
                  e.currentTarget.value.length === 0
                    ? null
                    : e.currentTarget.value;
                setState((state) => ({
                  ...state,
                  from: value,
                }));
              }}
              slotProps={{
                inputLabel: { shrink: true },
              }}
            />
            <FutureAppointments client_id={option?.id} />
            <FormErrorList formErrors={errors} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close()}>{t("form.cancel")}</Button>
          <Button
            type="submit"
            disabled={loading || serviceOption == null || from == null}
          >
            {loading && <ButtonLoader />}
            {t("form.submit")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
