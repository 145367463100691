import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Link from "../components/Link";
import { currentLocationFromRequest } from "../components/LocationSelector";
import * as api from "../services/api";
import { useLoaderData } from "react-router";
import { indexBy, round } from "shared/src/util.mjs";

/**
 * @typedef UsageReport
 * @property {Record<string, number>} usage
 * @property {string} from
 * @property {string} to
 * @property {object} capacity
 * @property {number} available_minutes
 *
 * @typedef ServiceGroup
 * @property {string} name
 * @property {string[]} services
 */

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  return api.loadReservationSettingsData({
    serviceFilter: {
      location_id: { eq: location_id },
    },
    serviceUsageReportParams: {
      location_id: location_id,
    },
    locationSettingsFilter: {
      location_id: { eq: location_id },
      key: { eq: "reservation_settings" },
    },
  });
}

/**
 * @typedef UsageReportRow
 * @property {string} service_id
 * @property {service} string
 * @property {number} minutes
 * @property {number} total_capacity_percent
 */
function usageReportRows(serviceUsageReport, serviceById) {
  const { available_minutes, usage } = serviceUsageReport;

  return Object.values(serviceById).map((service) => {
    const minutes = usage[service.id] ?? 0;

    return {
      service_id: service.id,
      service: service.name,
      minutes: minutes,
      total_capacity_percent: round((minutes / available_minutes) * 100, 2),
    };
  });
}

/**
 * @param {UsageReportRow[]} rows
 * @param {Record<string, ServiceGroup>} serviceGroups
 */
function usageReportRowGroups(rows, serviceGroups, serviceUsageReport) {
  const rowByServiceId = indexBy(rows, (r) => r.service_id);

  const groups = Object.entries(serviceGroups).map(([groupId, group]) => {
    return {
      id: groupId,
      name: group.name,
      services: group.services
        .map((service_id) => rowByServiceId[service_id])
        .filter((s) => s != null),
    };
  });

  const allServices = new Set(Object.keys(rowByServiceId));
  const assignedServices = new Set(
    Object.entries(serviceGroups).flatMap(([_, group]) => group.services),
  );
  const unassignedServices = allServices.difference(assignedServices);

  groups.push({
    id: "",
    name: "",
    services: Array.from(unassignedServices)
      .map((service_id) => rowByServiceId[service_id])
      .filter((s) => s != null),
  });

  return groups.map((g) => {
    return {
      ...g,
      capacity: serviceUsageReport.capacity.group[g.id]?.capacity,
      total_capacity_percent: round(
        (g.services.reduce((acc, r) => acc + r.minutes, 0) /
          serviceUsageReport.available_minutes) *
          100,
        2,
      ),
    };
  });
}

/**
 * @param {object} props
 * @param {UsageReport} props.serviceUsageReport
 * @param {Record<string, ServiceGroup>} props.serviceGroups
 */
function UsageReport({ serviceUsageReport, serviceById, serviceGroups }) {
  const { capacity } = serviceUsageReport;
  const rows = usageReportRows(serviceUsageReport, serviceById);
  const rowGroups = usageReportRowGroups(
    rows,
    serviceGroups,
    serviceUsageReport,
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={4}>
            týdny {capacity.from ?? 0} - {capacity.to ?? "..."}, dostupných
            minut: {serviceUsageReport.available_minutes}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Skupina</TableCell>
          <TableCell>Služba</TableCell>
          <TableCell>Rezervováno (m)</TableCell>
          <TableCell>Zabírá (%)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rowGroups.map((group) => {
          return group.services.map((service, idx) => {
            const {
              service_id,
              service: name,
              minutes,
              total_capacity_percent,
            } = service;

            return (
              <TableRow key={service_id}>
                {idx === 0 && (
                  <TableCell rowSpan={group.services.length}>
                    {group.name} ({group.total_capacity_percent}%{" "}
                    {group.capacity != null && ` / ${group.capacity}%`})
                  </TableCell>
                )}
                <TableCell>{name}</TableCell>
                <TableCell>{minutes}</TableCell>
                <TableCell>{total_capacity_percent}</TableCell>
              </TableRow>
            );
          });
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={3} />
          <TableCell>
            {round(
              (rows.reduce((acc, r) => acc + r.minutes, 0) /
                serviceUsageReport.available_minutes) *
                100,
              2,
            )}
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}

export default function ReservationSettings() {
  const loaderData = useLoaderData();
  const serviceById = indexBy(loaderData.data.services.data, (s) => s.id);
  const serviceGroups =
    loaderData.data.locationSettings?.data?.reservation_settings
      ?.service_groups ?? {};

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mb: 2 }}>
      <Button
        variant="contained"
        startIcon={<FontAwesomeIcon icon={faPencil} />}
        to="/reservation-settings/edit"
        component={Link}
      >
        Upravit parametry
      </Button>
      {loaderData.data.serviceUsageReport.map((r) => (
        <UsageReport
          key={r.from}
          serviceUsageReport={r}
          serviceById={serviceById}
          serviceGroups={serviceGroups}
        />
      ))}
    </Box>
  );
}
