import { JsonForms } from "@jsonforms/react";
import { materialRenderers } from "../jsonForms/materialRenderers.jsx";
import { materialCells } from "@jsonforms/material-renderers";
import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ServicesSelectInput from "./ServicesSelectInput.jsx";
import Grid from "@mui/material/Grid2";
import { useWizardContext } from "./AppointmentWizardContext.jsx";

const ServiceJsonFormComponent = ({
  serviceId,
  services,
  translation,
  formSubmitted,
}) => {
  const service = useMemo(
    () => services.find((t) => t.id === serviceId),
    [serviceId],
  );
  const { getFormPrefillData } = useWizardContext();

  //console.log(service);

  const initialData = useMemo(() => {
    if (!service?.template) return {};
    return getFormPrefillData(service.template);
  }, [service]);

  const [formState, setFormState] = useState(initialData);

  useEffect(() => {
    if (service?.template) {
      const prefillData = getFormPrefillData(service.template);
      setFormState((prev) => ({
        ...prev,
        ...prefillData,
      }));
    }
  }, [service, getFormPrefillData]);

  const handleFormChange = (data) => {
    setFormState(data);
  };

  return service && service.templates[0]?.template?.schema ? (
    <JsonForms
      schema={JSON.parse(service.templates[0]?.template.schema)}
      uischema={JSON.parse(service.templates[0]?.template.uischema)}
      data={formState}
      renderers={materialRenderers}
      cells={materialCells}
      onChange={({ data }) => handleFormChange(data)}
      config={{ forceShow: formSubmitted }}
      i18n={{ translate: translation }}
    />
  ) : (
    <div>Service nemá definovanou template</div>
  );
};

const AppointmentServices = ({ services, formSubmitted }) => {
  const { t, i18n } = useTranslation(["translation", "serviceTemplates"]);

  const [selectedServiceId, setSelectedServiceId] = useState("");

  const handleChange = (e) => setSelectedServiceId(e.target.value);
  //console.log(services);

  const translation = useMemo(
    () => (key, defaultMessage) => {
      // console.log(
      //   `Locale: ${i18n.language}, Key: ${key}, Default Message: ${defaultMessage}`,
      // );
      return i18n.exists(`serviceTemplates:${key}`)
        ? t(`serviceTemplates:${key}`)
        : defaultMessage;
    },
    [i18n.language],
  );

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t("appointmentNotes.serviceNotes")}
      </Typography>

      <Box mb="2">
        <Grid container spacing={2}>
          <Grid size={3} sx={{ display: "flex", alignItems: "center" }}>
            <ServicesSelectInput
              services={services}
              value={selectedServiceId}
              labelKey="appointmentNotes.service"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Box
          mt={2}
          sx={{ "& .MuiOutlinedInput-notchedOutline legend": { maxWidth: 0 } }}
        >
          <ServiceJsonFormComponent
            formSubmitted={formSubmitted}
            services={services}
            serviceId={selectedServiceId}
            translation={translation}
          />
        </Box>
        <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
      </Box>
    </>
  );
};

export default AppointmentServices;
