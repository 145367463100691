import React from "react";
import { createBrowserRouter } from "react-router";
import Homepage from "./page/Homepage";
import Calendar, { loader as calendarLoader } from "./page/Calendar";
import Notifications from "./page/Notifications";
import FCalendar, { loader as fcalendarLoader } from "./page/FCalendar";
import FEmployees, { loader as femployeesLoader } from "./page/FEmployees";
import Client, { loader as clientLoader } from "./page/Client";
import Clients, { loader as clientsLoader } from "./page/Clients";
import Services, { loader as servicesLoader } from "./page/Services";
import Reception, { loader as receptionLoader } from "./page/Reception";
import Locations, { loader as locationsLoader } from "./page/Locations";
import ReservationSettings, {
  loader as reservationSettingsLoader,
} from "./page/ReservationSettings";
import ReservationSettingsEdit, {
  loader as reservationSettingsEditLoader,
  action as reservationSettingsEditAction,
} from "./page/ReservationSettingsEdit";
import LocationEdit, {
  loader as locationEditLoadeer,
  action as locationEditAction,
} from "./page/LocationEdit";
import Calendars, { loader as calendarsLoader } from "./page/Calendars";
import CalendarEdit, {
  loader as calendarEditLoader,
  action as calendarEditAction,
} from "./page/CalendarEdit";
import ErrorElement from "./components/ErrorElement";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginPage from "./page/Login";
import { isGrantedAny, role } from "shared/src/roles.mjs";
import * as auth from "./auth";
import { currentLocationFromRequest } from "./components/LocationSelector";

function shouldRevalidate({ actionResult }) {
  if ((actionResult?.errors?.length ?? 0) > 0) {
    return false;
  }

  return true;
}

const error404 = {
  data: "",
  internal: false,
  status: 404,
  statusText: "Not Found",
};

async function dataStrategy({ matches, request }) {
  const matchesToLoad = matches.filter((m) => m.shouldLoad);

  const authToken = auth.tokenPayload(auth.getAuthenticationToken());
  const currentLocation = currentLocationFromRequest(request);
  const currentRoles = new Set(authToken?.roles?.[currentLocation] ?? []);
  if (
    !matchesToLoad.every((match) =>
      isGrantedAny(currentRoles, match.route?.handle?.anyRole),
    )
  ) {
    throw error404;
  }

  const results = await Promise.all(matchesToLoad.map((m) => m.resolve()));
  return results.reduce(
    (acc, result, i) =>
      Object.assign(acc, { [matchesToLoad[i].route.id]: result }),
    {},
  );
}

/** @type {import('react-router').RouteObject} */
const routes = [
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Homepage />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
  },
  {
    path: "/calendar",
    element: (
      <ProtectedRoute>
        <Calendar />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: calendarLoader,
    handle: {
      anyRole: new Set([role.Back_Office]),
    },
  },
  {
    path: "/fcalendar",
    element: (
      <ProtectedRoute>
        <FCalendar />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: fcalendarLoader,
    handle: {
      anyRole: new Set([role.Floor_Manager]),
    },
  },
  {
    path: "/femployees",
    element: (
      <ProtectedRoute>
        <FEmployees />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: femployeesLoader,
    handle: {
      anyRole: new Set([role.Floor_Manager]),
    },
  },
  {
    path: "/calendars",
    element: (
      <ProtectedRoute>
        <Calendars />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: calendarsLoader,
  },
  {
    path: "/calendars/:id/edit",
    element: (
      <ProtectedRoute>
        <CalendarEdit />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: calendarEditLoader,
    action: calendarEditAction,
    shouldRevalidate,
  },
  {
    path: "/clients",
    element: (
      <ProtectedRoute>
        <Clients />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: clientsLoader,
  },
  {
    path: "/clients/:id",
    element: (
      <ProtectedRoute>
        <Client />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: clientLoader,
  },
  {
    path: "/reception",
    element: (
      <ProtectedRoute>
        <Reception />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: receptionLoader,
    handle: {
      anyRole: new Set([role.Front_Office]),
    },
  },
  {
    path: "/reservation-settings",
    element: (
      <ProtectedRoute>
        <ReservationSettings />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: reservationSettingsLoader,
  },
  {
    path: "/reservation-settings/edit",
    element: (
      <ProtectedRoute>
        <ReservationSettingsEdit />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: reservationSettingsEditLoader,
    action: reservationSettingsEditAction,
    shouldRevalidate,
  },
  {
    path: "/services",
    element: (
      <ProtectedRoute>
        <Services />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: servicesLoader,
  },
  {
    path: "/locations",
    element: (
      <ProtectedRoute>
        <Locations />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: locationsLoader,
  },
  {
    path: "/locations/:id/edit",
    element: (
      <ProtectedRoute>
        <LocationEdit />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
    loader: locationEditLoadeer,
    action: locationEditAction,
    shouldRevalidate,
  },
  {
    path: "/login",
    element: <LoginPage />,
    errorElement: <ErrorElement />,
  },
  {
    path: "/notifications",
    element: (
      <ProtectedRoute>
        <Notifications />
      </ProtectedRoute>
    ),
    errorElement: <ErrorElement />,
  },
];

export const anyRoleByPath = routes.reduce((acc, route) => {
  const anyRole = route.handle?.anyRole;
  if (anyRole != null) {
    acc[route.path] = anyRole;
  }

  return acc;
}, {});

export const router = createBrowserRouter(routes, {
  dataStrategy: dataStrategy,
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
});
