/**
 * @param {string | null} s
 *
 * @returns {Date | null}
 */
export function parseDateTime(s) {
  if (s == null) {
    return null;
  }

  return new Date(s);
}

/**
 * @param {string | null}
 *
 * @returns {Date}
 */
export function parseUtcDateTime(s) {
  if (s == null) {
    return null;
  }

  return new Date(s + "Z");
}

/**
 * @param {Date | null} d
 *
 * @returns {string | null}
 */
export function toString(d) {
  if (d == null) {
    return null;
  }

  return new Date(d.getTime() - d.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -1)
    .replace("T", " ");
}

/**
 * @param {Date} d
 *
 * @returns {Date}
 */
export function startOfDay(d) {
  const r = new Date(d.getTime());
  r.setHours(0, 0, 0, 0);

  return r;
}

/**
 * @param {Date} d
 *
 * @returns {Date}
 */
export function endOfDay(d) {
  const r = new Date(d.getTime());
  r.setHours(23, 59, 59, 999);

  return r;
}

/**
 * @param {Date | null} date
 *
 * @returns {string | null}
 */
export function toDateString(date) {
  if (date == null) {
    return null;
  }

  return new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
}
