import React from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Form, useLoaderData, useNavigation } from "react-router";
import * as api from "../services/api";
import { currentLocationFromRequest } from "../components/LocationSelector";
import * as f from "../utils/formatter";
import {
  assocInIfUnset,
  compose,
  dissocInIf,
  indexBy,
  updateInIfExists,
} from "shared/src/util.mjs";
import { FormErrorList, useRouterFormAction } from "../form";
import ButtonLoader from "../components/ButtonLoader";
import { useTranslation } from "react-i18next";
import AutocompleteSelect from "../components/AutocompleteSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export async function loader({ request }) {
  const location_id = currentLocationFromRequest(request);

  return api.loadReservationSettingsEditData({
    serviceFilter: {
      location_id: { eq: location_id },
    },
    locationSettingsFilter: {
      location_id: { eq: location_id },
      key: { eq: "reservation_settings" },
    },
  });
}

export async function action({ request }) {
  const data = {
    location_id: currentLocationFromRequest(request),
    data: { reservation_settings: await request.json() },
  };

  return api.patchLocationSettings(data);
}

function ServiceGroupForm({ serviceById, serviceGroups, setServiceGroups }) {
  const serviceOptions = Object.values(serviceById).map((s) => ({
    id: s.id,
    name: `${s.name} (${f.minutesToDuration(s.duration)})`,
  }));

  return (
    <Box display="flex" flexDirection="column" gap="1rem" marginTop="1rem">
      {Object.entries(serviceGroups).map(([id, group]) => {
        return (
          <React.Fragment key={id}>
            <Box display="flex">
              <Box
                display="flex"
                flexDirection="column"
                gap="1rem"
                flexGrow="1"
              >
                <TextField
                  autoFocus
                  required
                  name={`service_groups.${id}.name`}
                  label="Název"
                  defaultValue={group.name}
                  onChange={(e) => {
                    const name = e.currentTarget.value;
                    setServiceGroups((g) => ({
                      ...g,
                      [id]: {
                        ...g[id],
                        name,
                      },
                    }));
                  }}
                />
                <FormControl>
                  <InputLabel>Služby</InputLabel>
                  <Select
                    required
                    label="Služby"
                    name={`service_groups.${id}.services`}
                    defaultValue={group.services}
                    multiple
                    onChange={(e) => {
                      const services = e.target.value;
                      setServiceGroups((g) => ({
                        ...g,
                        [id]: {
                          ...g[id],
                          services,
                        },
                      }));
                    }}
                  >
                    {serviceOptions.map((o) => (
                      <MenuItem key={o.id} value={o.id}>
                        {o.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <IconButton
                onClick={() => {
                  setServiceGroups((g) => dissocInIf(g, [id], () => true));
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Box>
            <Divider />
          </React.Fragment>
        );
      })}
      <Button
        onClick={() => {
          setServiceGroups((settings) => ({
            ...settings,
            [crypto.randomUUID()]: { name: "", services: [] },
          }));
        }}
      >
        Nová skupina
      </Button>
    </Box>
  );
}

function ServiceGroupCapacity({ groupOptions, weeklySettings: weekly }) {
  const [weeklySettings, setWeeklySettings] = React.useState(() => {
    return Object.fromEntries(weekly.map((s) => [crypto.randomUUID(), s]));
  });
  const groupNameById = Object.fromEntries(
    groupOptions.map((opt) => [opt.id, opt.name]),
  );

  return (
    <Box display="flex" flexDirection="column" gap="1rem" marginTop="1rem">
      {Object.entries(weeklySettings).map(([id, settings]) => {
        return (
          <React.Fragment key={id}>
            <Box key={id} display="flex">
              <Box
                display="flex"
                flexDirection="column"
                gap="1rem"
                flexGrow="1"
              >
                <TextField
                  required
                  defaultValue={settings.from}
                  name={`service_group_capacity_weekly.${id}.from`}
                  label="Od týdne"
                  type="number"
                />
                <TextField
                  required
                  defaultValue={settings.to}
                  name={`service_group_capacity_weekly.${id}.to`}
                  label="Do týdne"
                  type="number"
                />

                {Object.entries(settings.group).map(([groupId]) => {
                  return (
                    <Box key={groupId} display="flex">
                      <TextField
                        fullWidth={true}
                        autoFocus
                        required
                        defaultValue={settings.group[groupId].capacity}
                        name={`service_group_capacity_weekly.${id}.group.${groupId}.capacity`}
                        label={`${groupNameById[groupId]} kapacita (%)`}
                        type="number"
                      />
                      <IconButton
                        onClick={() => {
                          setWeeklySettings((settings) =>
                            dissocInIf(
                              settings,
                              [id, "group", groupId],
                              () => true,
                            ),
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </IconButton>
                    </Box>
                  );
                })}
                <AutocompleteSelect
                  label="Přidat skupinu"
                  options={groupOptions}
                  value={null}
                  onChange={(option) => {
                    if (option == null) {
                      return;
                    }

                    setWeeklySettings((settings) =>
                      assocInIfUnset(settings, [id, "group", option.id], {}),
                    );
                  }}
                />
              </Box>
              <IconButton
                onClick={() => {
                  setWeeklySettings((settings) =>
                    dissocInIf(settings, [id], () => true),
                  );
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </IconButton>
            </Box>
            <Divider />
          </React.Fragment>
        );
      })}

      <Button
        onClick={() => {
          setWeeklySettings((settings) => ({
            ...settings,
            [crypto.randomUUID()]: { group: {} },
          }));
        }}
      >
        Nová týdenní kapacita
      </Button>
    </Box>
  );
}

const formConverter = compose((formData) =>
  updateInIfExists(formData, ["service_group_capacity_weekly"], (v) =>
    Object.values(v),
  ),
);

export default function ReservationSettingsEdit() {
  const { t } = useTranslation();
  const loaderData = useLoaderData();
  const serviceById = indexBy(loaderData.data.services.data, (s) => s.id);
  const navigation = useNavigation();
  const formKey = React.useMemo(() => crypto.randomUUID(), [loaderData]);
  const { errors: formErrors, onSubmit } = useRouterFormAction({
    converter: formConverter,
  });
  const reservationSettings =
    loaderData.data.locationSettings.data?.reservation_settings ?? {};

  const [serviceGroups, setServiceGroups] = React.useState(
    reservationSettings.service_groups ?? {},
  );
  const groupOptions = Object.entries(serviceGroups).map(([k, v]) => ({
    id: k,
    name: v.name,
  }));

  return (
    <Form key={formKey} onSubmit={onSubmit}>
      Skupiny služeb
      <ServiceGroupForm
        serviceById={serviceById}
        serviceGroups={serviceGroups}
        setServiceGroups={setServiceGroups}
      />
      Kapacity služeb
      <ServiceGroupCapacity
        groupOptions={groupOptions}
        weeklySettings={reservationSettings.service_group_capacity_weekly ?? []}
      />
      <FormErrorList formErrors={formErrors} />
      <Button type="submit" disabled={navigation.state !== "idle"}>
        {navigation.state !== "idle" && <ButtonLoader />}
        {t("form.submit")}
      </Button>
    </Form>
  );
}
